<template>
  <div id="app">
    <router-view v-if="isAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isAlive: true
    }
  },
  methods: {
    reload() {
      this.isAlive = false;
      this.$nextTick(function () {
        this.isAlive = true
      })
    }
  }
}
</script>

<style lang="scss">
// @import './common/font/font.css';
#app{
  min-width: 1300px;
  div{
    box-sizing: border-box;
  }
  .shenglue{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
}
</style>
