import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      requireAuth: false
    },
    redirect:'/group_management/operations',
  },
  {
    path: '/',
    component: resolve => require(['../components/inAll.vue'], resolve),
    meta:{
      requireAuth: true,
    },
    children: [
      {
        path: '/group_management/operations',
        component: resolve => require(['../views/group_mgt/operations.vue'], resolve),
        name: 'operations'
      },
      {
        path: '/group_management/sales',
        component: resolve => require(['../views/group_mgt/sales.vue'], resolve),
        name: 'operations'
      },
      {
        path: '/group_management/sec_services',
        component: resolve => require(['../views/group_mgt/anfu.vue'], resolve),
        name: 'operations'
      },
      {
        path: '/group_management/plugins_sys',
        component: resolve => require(['../views/group_mgt/plugin.vue'], resolve),
        name: 'operations'
      },
      {
        path: '/hd_info',
        component: resolve => require(['../views/hd_info.vue'], resolve),
        name: 'hd_info'
      },
      {
        path: '/setting',
        component: resolve => require(['../views/setting.vue'], resolve),
        name: 'setting'
      }   
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
