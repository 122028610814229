import axios from 'axios'
// 注册和系统账号
function addAedit(form,url,callback){
    axios({
        method:'post',
        url: '/user/'+url,
        data:form
    }).then( res =>{
        callback({'code':res.data.code,'msg':res.data.msg})
    });
};
// 注销各系统账号
function logOff(form,callback){
  axios({
    method:'post',
    url: '/user/del_account',
    data:form
  }).then( res =>{
    callback({'code':res.data.code,'msg':res.data.msg})
  });
};

function convertKey (arr, key) {
    let newArr = [];
    arr.forEach((item, index) => {
      let newObj = {};
      for (var i = 0; i < key.length; i++) {
        newObj[key[i]] = item[Object.keys(item)[i]]
      }
      newArr.push(newObj);
    })
    // console.log(newArr)
    return newArr;
  }  
  
  // 表格样式方法
  function tableRowStyle({ row, rowIndex }){
    return 'background-color:#000;'
  };
  function tableHeaderColor({ row, column, rowIndex, columnIndex }){
    return 'color:#8f9399;font-weight: 600;font-size:12px;background-color: transparent;'
  };
  
  export default {
    logOff,
    addAedit,
    convertKey,
    tableRowStyle,
    tableHeaderColor,
  }